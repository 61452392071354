import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import Check from '@icons/checkbox_check.svg';
import { Label } from '@primitives';
import { rem } from 'polished';
import { FormattedMessage as M } from 'gatsby-plugin-intl';

export interface ICheckbox {
  checked: boolean;
  onChange: Function;
  id: string;
  label?: string;
  name?: string;
  value?: string;
  groupBox?: boolean;
}

const CheckboxContainer = styled.label`
  display: flex;
  margin-bottom: 1rem;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxLabel = styled(Label)<{ groupBox?: boolean }>`
  font-weight: 300;
  cursor: pointer;
`;

const StyledCheckbox = styled.div`
  width: ${rem('20px')};
  height: ${rem('20px')};
  transition: all 150ms;
  border: 1px solid ${(props) => props.theme.colors.black};
  position:relative;
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.boxShadow.input};
  cursor: pointer;

  ${HiddenCheckbox}:focus + & {
    box-shadow: ${(props) => props.theme.boxShadow.inputFocus};
  }

  svg {
    position: absolute;
    bottom: 5px;
    left: 5px;
  }
`;

const Checkbox: React.FC<ICheckbox> = ({
  name, id, checked, onChange, label, value, groupBox, ...rest
}) => {
  return (
    <CheckboxContainer {...rest} htmlFor={id}>
      <HiddenCheckbox
        checked={checked}
        // @ts-ignore
        onChange={onChange}
        id={id}
        name={name || id}
        value={value}
      />
      <StyledCheckbox>
        {checked && <Check />}
      </StyledCheckbox>
      {label && <CheckboxLabel groupBox={groupBox} htmlFor={id} pl="1"><M id={`fields.label.${label}`} /></CheckboxLabel>}
    </CheckboxContainer>
  );
};
export { Checkbox };
