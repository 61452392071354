import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// @ts-ignore
import { Checkbox, ICheckbox } from './Checkbox';

interface Props {
  id: string;
  name?: string;
  value: any;
  options: ICheckbox[];
  onChange: Function;
}

const CheckboxContainer = styled.fieldset`
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const CheckboxGroup: React.FC<Props> = ({
  options, name, id, value, onChange,
}) => {
  const [checkedBoxes, setCheckedBoxes] = useState(value);
  useEffect(() => {
    setCheckedBoxes(value);
  }, [value]);

  const onCheckboxChange = (e: React.FormEvent<HTMLInputElement>) => {
    let newChecked = checkedBoxes ? checkedBoxes.slice() : [];
    if (e.currentTarget.checked && !newChecked.includes(e.currentTarget.value)) {
      newChecked = [...newChecked, ...[e.currentTarget.value]];
    } else {
      newChecked = newChecked.filter((item: string) => item !== e.currentTarget.value);
    }

    setCheckedBoxes(newChecked);
    onChange(newChecked);
  };

  const isChecked = (checkboxId: string) => {
    return (checkedBoxes || []).includes(checkboxId)
  }
  if (!options) return <div />;

  return (
    <CheckboxContainer>
      {options.map(({ id: checkboxId, label }: ICheckbox) => (
        <Checkbox
          label={label || checkboxId}
          checked={isChecked(checkboxId)}
          key={checkboxId}
          id={`${name}_${checkboxId}`}
          name={name || id}
          onChange={onCheckboxChange}
          value={checkboxId}
          groupBox
        />
      ))}
    </CheckboxContainer>
  );
};
export { CheckboxGroup };
